/* header */
header,
.nav {
    position: sticky;
    padding-block: 5px;
    top: 0px;
    height: 72px;
    /* margin: auto; */
    z-index: 100;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    transition: all 0.5s ease-in-out;
    animation: fade-in 0.5s ease-in-out;
}

.headerStick {
    position: sticky;
    background-color: #000000;
    backdrop-filter: blur(85px);
}

.headerStick > .Navlinks > .navlinks > ul > li > a {
    color: #ffffff;
}

.headerStick > .Navlinks > .navlinks > ul > li > a.active {
    color: #d40000 !important;
}
.logo {
    cursor: pointer;
    height: 60px;
    /* background-image: url("../../assets/blck.png"); */
}

.Navlinks {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 72px;
    width: 100%;
}
.Navlinks > .navlinks > a {
    margin-top: 0px;
}
.Navlinks > .navlinks > ul {
    margin: auto;
    display: flex;
    align-items: center;
    height: 72px;
    justify-content: space-between;
    width: 81%;
    padding-left: 0px;
}
.Navlinks > .navlinks > ul > li {
    list-style-type: none;
}

.Navlinks > .navlinks > ul > li > a {
    font-size: 24px;
    line-height: 27px;
    color: #000000;
    text-decoration: none;
}
.active {
    color: #fff !important;
}
.navlinks > ul > li > a:hover {
    color: #fff;
}

@keyframes fade-in-right {
    0% {
        -webkit-transform: translateX(50px);
        transform: translateX(50px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

/* @-webkit-keyframes fadeinout {
  0%,100% { opacity: 0; }
  50% { opacity: 1; }
}

@keyframes fadeinout {
  0%,100% { opacity: 0; }
  50% { opacity: 1; }
} */

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

/* media queries */
@media only screen and (min-device-width: 0px) and (max-device-width: 400px) {
    .menu {
        width: 100%;
        display: flex;
    }

    .logo {
        max-width: 200px;
        height: 60px;

        width: auto;
        margin-left: 0px;
    }

    .Navlinks {
        display: none;
    }

    .toggledivq {
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.5s ease-in-out;
        /* height: 72px; */
        width: 100%;
    }

    .toggledivq > ul {
        margin: auto;
        display: flex;
        align-items: center;
        height: 72px;
        /* justify-content: space-between; */
        justify-content: center;
        width: 81%;
        padding-left: 0px;
        transition: all 0.5s ease-in-out;
    }
    .toggledivq > ul > li > a {
        /* display: none !important; */
        font-size: 16px;
        line-height: 27px;
        color: #000000;
        text-decoration: none;
        transition: all 0.5s ease-in-out;
    }
    .toggledivq > ul > li {
        list-style-type: none;
        transition: all 0.5s ease-in-out;
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 60px;
    }
    .active {
        color: #fff !important;
        /* -webkit-background-clip: text; */
        /* -webkit-text-fill-color: transparent; */
        /* background-clip: text; */
        /* text-fill-color: transparent; */
    }
    .toggledivq > ul > li > .hide {
        display: none;
    }
    .togglediv > ul > li > a > .logo {
        height: 60px;
    }

    .headerStick .toggledivq > ul {
        justify-content: space-between;
    }
    .headerStick .toggledivq > ul > li > .hide {
        display: flex;
        transition: all 0.5s ease-in-out;
    }

    .headerStick .toggledivq > ul > li > .logo img {
        height: 50px !important;
        /* transition: all 0.5s ease-in-out; */
        margin-left: 5px;
    }

    .headerStick > .toggledivq > ul > li > a {
        color: #ffffff;
    }

    .headerStick > .toggledivq > ul > li > a.active {
        color: #d40000 !important;
    }

    /*  */
}

@media only screen and (min-device-width: 400px) and (max-device-width: 769px) {
    .menu {
        width: 100%;
        display: flex;
    }

    .logo {
        max-width: 200px;
        height: 60px;

        width: auto;
        margin-left: 0px;
    }

    .Navlinks {
        display: none;
    }

    .toggledivq {
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.5s ease-in-out;
        /* height: 72px; */
        width: 100%;
    }

    .toggledivq > ul {
        margin: auto;
        display: flex;
        align-items: center;
        height: 72px;
        /* justify-content: space-between; */
        justify-content: center;
        width: 81%;
        padding-left: 0px;
        transition: all 0.5s ease-in-out;
    }
    .toggledivq > ul > li > a {
        /* display: none !important; */
        font-size: 20px;
        line-height: 27px;
        color: #000000;
        text-decoration: none;
        transition: all 0.5s ease-in-out;
    }
    .toggledivq > ul > li {
        list-style-type: none;
        transition: all 0.5s ease-in-out;
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 60px;
    }
    .active {
        color: #fff !important;
        /* -webkit-background-clip: text; */
        /* -webkit-text-fill-color: transparent; */
        /* background-clip: text; */
        /* text-fill-color: transparent; */
    }
    .toggledivq > ul > li > .hide {
        display: none;
    }
    .togglediv > ul > li > a > .logo {
        height: 60px;
    }

    .headerStick .toggledivq > ul {
        justify-content: space-between;
    }
    .headerStick .toggledivq > ul > li > .hide {
        display: flex;
        transition: all 0.5s ease-in-out;
    }

    .headerStick .toggledivq > ul > li > .logo img {
        height: 50px !important;
        /* transition: all 0.5s ease-in-out; */
        margin-left: 5px;
    }

    .headerStick > .toggledivq > ul > li > a {
        color: #ffffff;
    }

    .headerStick > .toggledivq > ul > li > a.active {
        color: #d40000 !important;
    }

    /*  */
}

@media only screen and (min-device-width: 769px) {
    .toggledivq {
        display: none;
    }
}
